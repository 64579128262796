/**
 * Layout component that queries for data
 * with Gatsby's StaticQuery component
 *
 * See: https://www.gatsbyjs.org/docs/static-query/
 */

import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { StaticQuery, graphql } from 'gatsby';

import Nav from './nav/Nav';
import SEO from './seo';
import Footer from './Footer';
import '../stylesheets/index.scss';

const Layout = ({ seo, children }) => {
  const [noScroll, toggleNoScroll] = useState(false);

  return (
    <StaticQuery
      query={graphql`
        query SiteTitleQuery {
          site {
            siteMetadata {
              title
            }
          }
        }
      `}
      render={data => (
        <>
          <SEO
            description={seo.description}
            keywords={seo.keywords}
            title={seo.title}
            url={seo.url}
            image={seo.image}
          />
          <Nav
            toggleNoScroll={toggleNoScroll}
            siteTitle={data.site.siteMetadata.title}
          />
          <div
            style={
              noScroll
                ? {
                    display: 'none'
                  }
                : {}
            }
          >
            <main>{children}</main>
            <Footer />
          </div>
        </>
      )}
    />
  );
};

Layout.propTypes = {
  children: PropTypes.node.isRequired
};

export default Layout;
