const theme = {
  firstFont: 'Open Sans',
  black: '#000',
  white: '#fff',
  darkblue: '#1a212e',
  blue: '#44417b',
  red: '#ea5252',
  lightgrey: '#f9f9f9',
  grey: '#8e8e8e',
  darkgrey: '#424152',
};

export default theme;
