/**
 * SEO component that queries for data with
 *  Gatsby's useStaticQuery React hook
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

import React from 'react';
import PropTypes from 'prop-types';
import Helmet from 'react-helmet';
import { useStaticQuery, graphql } from 'gatsby';

import appleTouchIcon from '../images/favicons/apple-touch-icon.png';
import thirtytwo from '../images/favicons/favicon-32x32.png';
import sixteen from '../images/favicons/favicon-16x16.png';
import favicon from '../images/favicons/favicon.ico';

// eslint-disable-next-line react/prop-types
const SEO = ({ description, lang, meta, keywords, title, url, image }) => {
  const { site } = useStaticQuery(
    graphql`
      query {
        site {
          siteMetadata {
            title
            description
            author
          }
        }
      }
    `
  );

  const metaDescription = description || site.siteMetadata.description;
  const pageTitle = title || site.siteMetadata.title;

  return (
    <Helmet
      htmlAttributes={{
        lang
      }}
      title={pageTitle}
      // titleTemplate={`%s | ${site.siteMetadata.title}`}
      link={[
        {
          rel: 'apple-touch-icon',
          sizes: '180x180',
          href: `${appleTouchIcon}`
        },
        {
          rel: 'icon',
          type: 'image/png',
          sizes: '32x32',
          href: `${thirtytwo}`
        },
        { rel: 'icon', type: 'image/png', sizes: '16x16', href: `${sixteen}` },
        { rel: 'shortcut icon', href: `${favicon}` }
      ]}
      meta={[
        {
          name: `description`,
          content: metaDescription
        },
        {
          property: `og:title`,
          content: title
        },
        {
          property: `og:description`,
          content: metaDescription
        },
        {
          property: `og:type`,
          content: `website`
        },
        {
          property: 'og:url',
          content: url
        },
        {
          property: 'og:image',
          content: image
        },
        {
          property: 'fb:app_id',
          content: 1570878686357324
        }
        // {
        //   name: `twitter:card`,
        //   content: `summary`,
        // },
        // {
        //   name: `twitter:creator`,
        //   content: site.siteMetadata.author,
        // },
        // {
        //   name: `twitter:title`,
        //   content: title,
        // },
        // {
        //   name: `twitter:description`,
        //   content: metaDescription,
        // },
      ]
        .concat(
          keywords.length > 0
            ? {
                name: `keywords`,
                content: keywords.join(`, `)
              }
            : []
        )
        .concat(meta)}
    />
  );
};

SEO.defaultProps = {
  lang: `en`,
  meta: [],
  keywords: []
};

SEO.propTypes = {
  // eslint-disable-next-line react/require-default-props
  description: PropTypes.string,
  lang: PropTypes.string,
  // eslint-disable-next-line react/forbid-prop-types
  meta: PropTypes.array,
  keywords: PropTypes.arrayOf(PropTypes.string),
  title: PropTypes.string.isRequired
};

export default SEO;
